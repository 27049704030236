import React from 'react'
import Layout from '../layout/layout'
import { graphql, PageProps } from 'gatsby'
import { MdxArticleFields } from '../models/mdx'
import ArticlesList from '../components/articlesList'
import Section from '../components/section'

type DataProps = {
  allMdx: {
    nodes: MdxArticleFields[]
  }
}

const CloudNativePage: React.FC<PageProps<DataProps>> = ({
  data: {
    allMdx: { nodes },
  },
}) => (
  <Layout
    pageTitle="云原生"
    pageSubTitle="云原生（Cloud Native）相关的技术内容"
  >
    <Section transparent={true}>
      <ArticlesList articles={nodes} />
    </Section>
  </Layout>
)

export default CloudNativePage

export const query = graphql`
  query {
    allMdx(filter: { frontmatter: { category: { eq: "cloudnative" } } }, sort: {fields: frontmatter___published_at, order: DESC}) {
      nodes {
        ...MdxArticleFields
      }
    }
  }
`
